export default Object.freeze({
  name: 'Aleksandr Skoropletov',
  title: 'Lead Software Engineer',
  started: 2007,
  remotely: 2013,
  stack: [
    'NodeJS',
    'Typescript',
    'Rust',
    'React',
    'PostgreSQL',
    'Microsoft Azure',
    'Google Cloud',
    'AWS',
    'git',
    'Cheminformatics',
    'LIMS',
  ],
  contacts: [
    {
      name: 'My CV',
      address: 'Aleksandr Skoropletov, Lead Software Engineer',
      link: '/CV-Aleksandr-Skoropletov-Software-Engineer.pdf',
    },
    {
      name: 'E-mail',
      address: 'alex@skoropletov.ru',
      link: 'mailto:alex@skoropletov.ru',
    },
    {
      name: 'GitHub',
      address: '@alexskoropletov',
      link: 'https://github.com/alexskoropletov',
    },
  ],
});
